// extracted by mini-css-extract-plugin
export var root = "PlasmicPlan-module--root--27EcQ";
export var box__tI40O = "PlasmicPlan-module--box__tI40O--3_Nyh";
export var box__gyZu3 = "PlasmicPlan-module--box__gyZu3--1nDJf";
export var slotName = "PlasmicPlan-module--slotName--1clZ5";
export var box__fvPOf = "PlasmicPlan-module--box__fvPOf--3r5JP";
export var box__c05Ne = "PlasmicPlan-module--box__c05Ne--11oH4";
export var bullet__vfnbG = "PlasmicPlan-module--bullet__vfnbG--LaiPj";
export var bullet___4QBfm = "PlasmicPlan-module--bullet___4QBfm--3bdB_";
export var bullet__b5Jgc = "PlasmicPlan-module--bullet__b5Jgc--2cRYT";
export var box___5HIf7 = "PlasmicPlan-module--box___5HIf7--19B_x";
export var svg___7XxrC = "PlasmicPlan-module--svg___7XxrC--13iyC";