// extracted by mini-css-extract-plugin
export var root = "PlasmicPricing-module--root--rcns1";
export var box__kRBjL = "PlasmicPricing-module--box__kRBjL--D1-s8";
export var header = "PlasmicPricing-module--header--arUMc";
export var section__fMhyy = "PlasmicPricing-module--section__fMhyy--1GfRq";
export var box___3XQr = "PlasmicPricing-module--box___3XQr--2bsvi";
export var plan__cDp3V = "PlasmicPricing-module--plan__cDp3V--2-46Q";
export var box__qzuZp = "PlasmicPricing-module--box__qzuZp--20ik0";
export var box__fbsIv = "PlasmicPricing-module--box__fbsIv--3k-qM";
export var bullet___1XkFs = "PlasmicPricing-module--bullet___1XkFs--1pkly";
export var bullet__o7YEv = "PlasmicPricing-module--bullet__o7YEv--2SKkH";
export var bullet__bhN1T = "PlasmicPricing-module--bullet__bhN1T--103ID";
export var plan__p0H0H = "PlasmicPricing-module--plan__p0H0H--2I8j9";
export var box__vGuuf = "PlasmicPricing-module--box__vGuuf--1Lgi_";
export var box__jB1Qj = "PlasmicPricing-module--box__jB1Qj--6EQr7";
export var bullet__uITfA = "PlasmicPricing-module--bullet__uITfA--2gLgi";
export var bullet__uwzgi = "PlasmicPricing-module--bullet__uwzgi--1PvuY";
export var bullet___9OVeN = "PlasmicPricing-module--bullet___9OVeN--3YfYI";
export var bullet__sa7N = "PlasmicPricing-module--bullet__sa7N--1ebrd";
export var plan__aiSwE = "PlasmicPricing-module--plan__aiSwE--3N_r_";
export var box__lFwGu = "PlasmicPricing-module--box__lFwGu--_T6fX";
export var box__nYsy4 = "PlasmicPricing-module--box__nYsy4--6tQ2B";
export var bullet__kbjVo = "PlasmicPricing-module--bullet__kbjVo--YjDLh";
export var bullet__rew7P = "PlasmicPricing-module--bullet__rew7P--3TWWG";
export var bullet__uEa2G = "PlasmicPricing-module--bullet__uEa2G--27EMo";
export var section__kPtnn = "PlasmicPricing-module--section__kPtnn--3aoJ3";
export var faq___7E9Fd = "PlasmicPricing-module--faq___7E9Fd--3lMka";
export var faq___1Yqso = "PlasmicPricing-module--faq___1Yqso--17Omf";
export var faq__k8AUp = "PlasmicPricing-module--faq__k8AUp--Vf8u2";
export var footer = "PlasmicPricing-module--footer--k54-O";